import React, { useEffect, useState } from 'react';
import { NavLink, matchPath, useLocation } from 'react-router-dom';
import styles from './NavBar.module.scss';
import classnames from 'classnames';
import ClickableDiv from '../ClickableDiv/ClickableDiv';
import classNames from 'classnames';
import { BsDiscord } from 'react-icons/bs';
import { GrInstagram, GrTwitter } from 'react-icons/gr';
import opensea from '../../../assets/images/misc/opensea.svg';
import whitePaperImage from '../../../assets/images/misc/whitepaper.png';
import useConnect from '../../../utils/useConnect';

const Hamburger = ({ onClick, isOpen }) => {
  return (
    <ClickableDiv onClick={onClick} className={classnames(styles['hamburger'], { [styles['open']]: isOpen })}>
      <span />
      <span />
      <span />
    </ClickableDiv>
  );
};
const NavBar = () => {
  let location = useLocation();
  const { ConnectRender, SwitchRender } = useConnect();
  const canOverlap = matchPath(location.pathname, {
    path: '/',
    exact: true,
    strict: false,
  });
  const [showMenu, setShowMenu] = useState(false);

  const links = [
    { to: '/build-rooster', text: 'Build', show: () => process.env.REACT_APP_ENV === 'DEV' },
    { to: '/coop', text: 'Coop' },
    { to: '/items', text: 'Items' },
    { to: '/roosters', text: 'Roosters' },
  ].filter(({ show }) => (!show ? true : show()));

  useEffect(() => {
    const bodyClass = styles['body'];
    if (showMenu) {
      if (!document.body.classList.contains(bodyClass)) {
        document.body.style = `margin-right: ${window.innerWidth - document.body.offsetWidth}px`;
        document.body.classList.add(bodyClass);
      }
      return () => {
        document.body.style = null;
        document.body.classList.remove(bodyClass);
      };
    } else {
      document.body.style = null;
      document.body.classList.remove(bodyClass);
    }
  }, [showMenu]);

  return (
    <div className={classnames(styles['topNavContainer'], { [styles.overlap]: canOverlap })}>
      <li className={classnames(styles['navLi'], styles['logo-navLi'])}>
        <NavLink
          className={classnames(styles['navItem'], styles['logo-nav-item'])}
          to="/"
          onClick={() => {
            setShowMenu(false);
          }}
        ></NavLink>
      </li>
      {links.map((link, i) => (
        <li key={i} className={classnames(styles['navLi'], styles['topMenuLi'])}>
          <NavLink className={styles['navItem']} activeClassName={styles['active']} to={link.to}>
            {link.text}
          </NavLink>
        </li>
      ))}
      <div className={classnames(styles['side-menu'], { [styles['active']]: showMenu })}>
        <div className={styles['side-menu-container']}>
          <div className={styles['side-menu-links']}>
            {links.map((link, i) => (
              <li key={i} className={classnames(styles['navLi'], styles['sideMenuLi'])}>
                <NavLink
                  className={styles['navItem']}
                  to={link.to}
                  onClick={() => {
                    setShowMenu(false);
                  }}
                >
                  {link.text}
                </NavLink>
              </li>
            ))}
          </div>
          <div className={styles['socialsContainer']}>
            <ConnectRender
              TagName={NavLink}
              className={() => classNames(styles['navItem'], styles['subItem'])}
              wrapper={(x, i) => (
                <li key={'ConnectRender_' + i} className={classnames(styles['navLi'], styles['sideMenuLi'])}>
                  {x}
                </li>
              )}
            />
            <SwitchRender
              TagName={NavLink}
              className={() => classNames(styles['navItem'], styles['subItem'])}
              wrapper={(x, i) => (
                <li key={'SwitchRender_' + i} className={classnames(styles['navLi'], styles['sideMenuLi'])}>
                  {x}
                </li>
              )}
            />
            <a href="https://discord.gg/aKBWnXrYDQ" target="_blank" rel="noopener noreferrer">
              <BsDiscord size="32" /> @rowdyrooster
            </a>
            <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
              <GrInstagram size="32" /> @rowdyrooster
            </a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
              <GrTwitter size="32" /> @rowdyrooster
            </a>
            <a href="https://opensea.io/collection/rowdy-rooster-collective" target="_blank" rel="noopener noreferrer">
              <img className={styles['openseaSvg']} src={opensea} size="32" alt={"opensea"} /> Rowdy Rooster Collective
            </a>
          </div>
        </div>
        <div className={styles['whitepaper']}>
          <a
            className={styles['whitepaperText']}
            href={'https://rowdyrooster.io/cdn/assets/RRC+-+Whitepaper+1.26.22.pdf'}
            target="_blank"
            rel="noreferrer"
          >
            <div>View White Paper</div>
            <img src={whitePaperImage} alt={'whitepaper'} />
          </a>
        </div>
      </div>
      <Hamburger
        onClick={() => {
          setShowMenu(!showMenu);
          window.scrollTo(0, 0);
        }}
        isOpen={showMenu}
      />
    </div>
  );
};
export default NavBar;
