import React, { Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import Footer from './components/Footer/Footer';
import NavBar from './components/NavBar/NavBar';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import Terms from './pages/Terms/Terms';

const Coop = React.lazy(() => import('./pages/Coop/Coop'));
const Roosters = React.lazy(() => import('./pages/Roosters/Roosters'));
const Rooster = React.lazy(() => import('./pages/Rooster/Rooster'));
const Items = React.lazy(() => import('./pages/Items/Items'));
const BuildRooster = React.lazy(() => import('./pages/BuildRooster/BuildRooster'));
const ErrorPage = React.lazy(() => import('./pages/ErrorPage/ErrorPage'));
const Landing = React.lazy(() => import('./pages/Landing/Landing'));

const RRoute = ({ path, component: Component, render, suspense, ...props }) => {
  let route = render ? render() : <Component />;

  if (suspense)
    return (
      <Route {...props} path={path}>
        <Suspense fallback={null}>{route}</Suspense>
      </Route>
    );
  else
    return (
      <Route {...props} path={path}>
        {route}
      </Route>
    );
};

function LandingRouter({ match }) {
  return (
    <>
      <NavBar />
      <div style={{ minHeight: '80vh' }}>
        <ErrorBoundary>
          <Switch>
            <RRoute exact path="/" suspense={true} component={Landing} />
            <RRoute exact path="/rooster/:tokenId" suspense={true} component={Rooster} />
            <RRoute exact path="/roosters" suspense={true} component={Roosters} />
            <RRoute exact path="/items" suspense={true} component={Items} />
            <RRoute exact path="/coop" suspense={true} component={Coop} />
            <RRoute exact path="/build-rooster/:tokenId" suspense={true} component={BuildRooster} />
            <RRoute exact path="/build-rooster" suspense={true} component={BuildRooster} />
            <RRoute exact path="/terms" suspense={true} component={Terms} />
            <RRoute suspense={true} render={() => <ErrorPage errorCode={404} />} />
          </Switch>
        </ErrorBoundary>
      </div>
      <Footer />
    </>
  );
}

export default LandingRouter;
