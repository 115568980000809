import axios from './axios';

// types = [
//   "random-rooster",
//   "custom-rooster",
//   "items",
//   "edit-rooster"
// ]
export const getCost = (type, items, tokenId) => {
  return axios.post(`/cost`, { type, items, tokenId }).then((r) => r.data);
};
