import React, { useEffect, useState } from 'react';
import styles from './Footer.module.scss';
import { GrInstagram, GrTwitter } from 'react-icons/gr';
import { BsDiscord } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { getPaymentAddress, getContractAddress } from '../../../api/addresses';
import opensea from '../../../assets/images/misc/opensea.svg';
const links = [
  { name: 'Rooster Coop', url: '/coop' },
  { name: 'Items', url: '/items' },
  { name: 'Roosters', url: '/roosters' },
  { name: 'Homepage', url: '/' },
  { name: 'NFT Terms of Use', url: '/terms' },
];
const Footer = () => {
  const [contractAddress, setContractAddress] = useState(null);
  const [itemPaymentAddress, setItemPaymentAddress] = useState(null);
  useEffect(() => {
    getContractAddress().then(({ address }) => {
      setContractAddress(address);
    });
    getPaymentAddress().then(({ address }) => {
      setItemPaymentAddress(address);
    });
  }, []);
  return (
    <div className={styles['footer']}>
      <div className={styles['footer-link-container']}>
        <div className={styles['links']}>
          <h3 className={styles['title']}>Links</h3>
          {links.map((link, i) => (
            <Link key={i} to={link.url}>
              {link.name}
            </Link>
          ))}
        </div>
        <div className={styles['socialsContainer']}>
          <h3 className={styles['title']}>Social</h3>
          <a href="https://discord.gg/aKBWnXrYDQ" target="_blank" rel="noopener noreferrer">
            <BsDiscord size="32" /> @rowdyrooster
          </a>
          <a href="https://www.instagram.com/rowdyrooster.io/" target="_blank" rel="noopener noreferrer">
            <GrInstagram size="32" /> @rowdyrooster
          </a>
          <a href="https://twitter.com/rowdyrooster_io" target="_blank" rel="noopener noreferrer">
            <GrTwitter size="32" /> @rowdyrooster
          </a>
          <a href="https://opensea.io/collection/rowdy-rooster-collective" target="_blank" rel="noopener noreferrer">
            <img className={styles['openseaSvg']} src={opensea} size="32" alt={"opensea"} /> Rowdy Rooster Collective
          </a>
          <div className={styles['copyright']}>© {new Date().getFullYear()} All Rights Reserved.</div>
        </div>
      </div>
      <div className={styles['address-container']}>
        <div>
          Contract Address:{' '}
          <a href={`https://etherscan.io/address/${contractAddress}`} target="_blank" rel="noopener noreferrer">
            {contractAddress}
          </a>
        </div>
        <div>
          Item Payment Address:{' '}
          <a href={`https://etherscan.io/address/${itemPaymentAddress}`} target="_blank" rel="noopener noreferrer">
            {itemPaymentAddress}
          </a>
        </div>
      </div>
    </div>
  );
};
export default Footer;
