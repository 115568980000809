import { getRoosters } from '../roosters/roosters.actions';
import { SET_ADDRESS, SET_CONNECTED, SET_CONNECTOR, SET_WALLET_CONNECT_SIGNER, SET_WALLET_ERROR } from '../types';

export const setAddress = (address, chainId) => (dispatch, getState) => {
  const oldAddress = getState().wallet.address;
  dispatch({ type: SET_ADDRESS, payload: { address, chainId } });
  if (address !== oldAddress || !getState().roosters.roosters) dispatch(getRoosters());
};

export const setConnected = (connected) => (dispatch) => {
  dispatch({ type: SET_CONNECTED, payload: { connected } });
};

export const setConnector = (connectorType) => (dispatch) => {
  dispatch({ type: SET_CONNECTOR, payload: { connectorType } });
};
export const setWalletConnectSigner = (walletConnectSigner) => (dispatch) => {
  dispatch({ type: SET_WALLET_CONNECT_SIGNER, payload: { walletConnectSigner } });
};

export const setWalletError = (error) => (dispatch) => {
  dispatch({ type: SET_WALLET_ERROR, payload: { error } });
};
