import classNames from 'classnames';
import React from 'react';
import { useHistory } from 'react-router';
import ClickableDiv from '../ClickableDiv/ClickableDiv';
import styles from './Button.module.scss';

const Button = ({ disabled, children, onClick, className, icon, classes, to }) => {
  let history = useHistory();
  const handleClick = (e) => {
    to && history.push(to);
    onClick && onClick(e);
  };
  return (
    <ClickableDiv data-nosnippet onClick={handleClick} className={classNames(styles['btn'], className, { [styles['disabled']]: disabled })}>
      {icon && <span className={classNames(styles['icon'], classes.icon)}>{icon}</span>}
      {children}
    </ClickableDiv>
  );
};
Button.defaultProps = {
  classes: {},
};
export default Button;
