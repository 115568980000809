import React, { useEffect, useState } from 'react';
import { _purchase } from '../../../store/purchases/purchases.actions';
import EggCanvas from './EggCanvas/EggCanvas';
import Popup from '../Popup/Popup';
import { useDispatch } from 'react-redux/es/hooks/useDispatch';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import {
  attemptPreviousTransaction,
  completeTransaction,
  rejectCurrentTransaction,
} from '../../../store/transactions/transactions.actions';
import RoosterCanvas from './RoosterCanvas/RoosterCanvas';
import HatchCanvas from './HatchCanvas/HatchCanvas';
import NameChangeForm from './NameChangeForm/NameChangeForm';
import { getRoosters } from '../../../store/roosters/roosters.actions';

const Purchases = () => {
  const [step, setStep] = useState('');
  const [txType, setTxType] = useState(null);
  const [transactionStarted, setTransactionStarted] = useState(false);
  const [result, setResult] = useState(null);
  const dispatch = useDispatch();
  const { pool, currentTransaction } = useSelector((state) => state.transactions);
  const [unfinishedTXCount, setUnfinishedTXCount] = useState(null);

  const sendPurchase = async (extraData = {}) => {
    if (currentTransaction && !transactionStarted) {
      const { type, restored, ...data } = currentTransaction;
      if (restored) return;
      setTransactionStarted(true);
      try {
        const res = await dispatch(_purchase(type, { ...extraData, ...data }, setStep));
        setResult(res);
      } catch (e) {
        if (e.message === 'Transaction already completed') {
          dispatch(completeTransaction({ type, ...data }));
          dispatch(getRoosters());
        } else if (txType !== 'change-name')
          // Dont close the tx window on name change
          dispatch(rejectCurrentTransaction());
        setStep(e.message);
      }
      setTransactionStarted(false);
    }
  };

  useEffect(() => {
    // Ignore if there is a transaction that is set
    if (!txType) {
      if (currentTransaction) {
        setTxType(currentTransaction.type);
      } else {
        const unfinishedTransactions = Object.values(pool || {});
        if (unfinishedTransactions.length > 0) {
          const tx = unfinishedTransactions[0];
          dispatch(attemptPreviousTransaction(tx));
          setUnfinishedTXCount(tx?.attempt || 0);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pool, txType, currentTransaction]);

  const handleClose = () => {
    setResult(null);
    setTxType(null);
    setStep('');
    setUnfinishedTXCount(null);
    setTransactionStarted(false);

    if (currentTransaction) dispatch(rejectCurrentTransaction());
  };

  return (
    <>
      {txType === 'random-rooster' && (
        <Popup
          title={unfinishedTXCount != null ? `Mint Transaction Attempt #${unfinishedTXCount}` : 'Mint Rooster'}
          hideX={currentTransaction}
          onClose={handleClose}
        >
          <EggCanvas step={step} rooster={result} onLoad={sendPurchase} />
        </Popup>
      )}
      {txType === 'edit-rooster' && (
        <Popup
          title={unfinishedTXCount != null ? `Edit Transaction Attempt #${unfinishedTXCount}` : 'Edit Rooster'}
          hideX={currentTransaction}
          onClose={handleClose}
        >
          <RoosterCanvas step={step} rooster={result} onLoad={sendPurchase} />
        </Popup>
      )}
      {txType === 'hatch-egg' && (
        <Popup
          title={unfinishedTXCount != null ? `Hatch Transaction Attempt #${unfinishedTXCount}` : 'Hatch Egg'}
          hideX={currentTransaction}
          onClose={handleClose}
        >
          <HatchCanvas step={step} rooster={result || currentTransaction?.rooster} onLoad={sendPurchase} />
        </Popup>
      )}
      {txType === 'change-name' && (
        <Popup
          title={unfinishedTXCount != null ? `Change Name #${unfinishedTXCount}` : 'Change Name'}
          hideX={transactionStarted && currentTransaction}
          onClose={handleClose}
        >
          <NameChangeForm
            step={step}
            rooster={result || currentTransaction?.rooster}
            disabled={transactionStarted}
            onSubmit={sendPurchase}
          />
        </Popup>
      )}
    </>
  );
};
export default Purchases;
