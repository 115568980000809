import React, { useEffect } from 'react';
import './App.scss';
import { Route, BrowserRouter, Switch, useHistory } from 'react-router-dom';
// import Landing from './layout/pages/Landing/Landing';
import LandingRouter from './layout/LandingRouter';
import { PersistGate } from 'redux-persist/integration/react';
import createStore from './store/config';
import { Provider, useDispatch } from 'react-redux';
import Purchases from './layout/components/Purchases/Purchases';
import { getRoosterCount, getRoosters } from './store/roosters/roosters.actions';
import { useEventListener } from './utils/EventListener';
import useConnect from './utils/useConnect';
const { store, persistor } = createStore();
const CommonFunctions = () => {
  const dispatch = useDispatch();
  const { address } = useConnect();
  useEventListener('rooster-modified', () => dispatch(getRoosters()), [address]);

  useEffect(() => {
    dispatch(getRoosterCount());
    const ev = setInterval(() => {
      dispatch(getRoosterCount());
    }, 1200000);
    return () => {
      clearInterval(ev);
    };
  }, []);
  return null;
};
const ScrollToTop = ({ children }) => {
  let history = useHistory();
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, []);

  return <>{children}</>;
};
const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <CommonFunctions />
        <BrowserRouter>
          <ScrollToTop>
            <Switch>
              <Route component={LandingRouter} />
            </Switch>
          </ScrollToTop>
        </BrowserRouter>
        <Purchases />
      </PersistGate>
    </Provider>
  );
};
export default App;
