import React from 'react';
import { Suspense } from 'react';
import { withRouter } from 'react-router-dom';
const ErrorPage = React.lazy(() => import('../../pages/ErrorPage/ErrorPage'));

class ErrorBoundary extends React.Component {
  state = {
    uiError: null,
    info: null,
  };
  static getDerivedStateFromError(error) {
    const statusCode = parseInt(error.message);
    return { uiError: error, statusCode: isNaN(statusCode) && statusCode.toString() === error.message ? 500 : statusCode };
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.setState({ uiError: null, statusCode: null });
    }
  }
  componentDidCatch(error) {
    const statusCode = parseInt(error.message);
    this.setState({ uiError: error, statusCode: isNaN(statusCode) && statusCode.toString() === error.message ? 500 : statusCode });
  }
  render() {
    const { uiError, statusCode } = this.state;

    if (uiError) {
      return (
        <Suspense fallback={null}>
          <ErrorPage message={uiError.toString()} errorCode={statusCode} />
        </Suspense>
      );
    }
    return this.props.children;
  }
}
export default withRouter(ErrorBoundary);
