import { getRoostersByAddress, countRoosters } from '../../api/roosters';
import { SET_ROOSTERS, COUNT_ROOSTERS } from '../types';

export const getRoosters = () => (dispatch, getState) => {
  const address = getState().wallet.address;
  if (address) {
    dispatch({ type: SET_ROOSTERS, payload: { roosters: [] } });
    getRoostersByAddress(address).then(({ data }) => {
      dispatch({ type: SET_ROOSTERS, payload: { roosters: data } });
    });
  } else {
    dispatch({ type: SET_ROOSTERS, payload: { roosters: null } });
  }
};
export const getRoosterCount = () => (dispatch) => {
  countRoosters().then(({ count }) => {
    dispatch({ type: COUNT_ROOSTERS, payload: { count } });
  });
};
