import classNames from 'classnames';
import React from 'react';
import styles from './ClickableDiv.module.scss';

const ClickableDiv = ({ onClick, className, children, ...props }) => {
  return (
    <div
      {...(onClick
        ? {
            onClick: onClick,
            role: 'button',
            tabIndex: 0,
            className: classNames(styles['div'], className),
            onKeyPress: (e) => ['Enter', 'NumpadEnter'].includes(e.key) && onClick(e),
          }
        : { className })}
      {...props}
    >
      {children}
    </div>
  );
};
export default ClickableDiv;
