import React, { useEffect, useRef, useCallback } from 'react';
import styles from './EggCanvas.module.scss';
import eggLeft from '../../../../assets/images/egg/egg-left-spritesheet.png';
import eggRight from '../../../../assets/images/egg/egg-right-spritesheet.png';
import egg from '../../../../assets/images/egg/egg_plain.png';
import { Tween, update as tweenUpdate, Easing } from '@tweenjs/tween.js';
import HatchButton from '../../HatchButton/HatchButton';

const EggCanvas = ({ rooster, step, onLoad }) => {
  const canvasRef = useRef(null);
  const bufferRef = useRef(null);
  const isLoaded = useRef(false);
  useEffect(() => onLoad(), []);
  // Storage for image data
  const sourceLookup = useRef({
    egg: {
      path: egg,
      x: 75,
      y: 0,
      width: 455,
      height: 506,
      scrollOffset: 1,
      rotate: 0,
      originX: 455 / 2,
      originY: 506,
      animations: (_this) => [
        new Tween(_this)
          .to({ rotate: 5 }, 250)
          .chain(
            new Tween(_this)
              .to({ rotate: -5 }, 500)
              .chain(new Tween(_this).to({ rotate: 5 }, 500).easing(Easing.Quadratic.InOut).delay(1000).yoyo(true).repeat(Infinity))
          ),
      ],
    },
    eggLeft: {
      path: eggLeft,
      x: 75,
      y: 0,
      width: 455,
      height: 506,
      scrollOffset: 1,
      frame: 0,
      frames: 3,
      rotate: 0,
      originX: 455 / 2,
      originY: 506,
      animations: (_this) => [
        new Tween(_this)
          .to({ rotate: 5 }, 250)
          .chain(
            new Tween(_this)
              .to({ rotate: -5 }, 500)
              .chain(new Tween(_this).to({ rotate: 5 }, 500).easing(Easing.Quadratic.InOut).delay(1000).yoyo(true).repeat(Infinity))
          ),
        new Tween(_this).to({ frame: _this.frames }, 250).delay(1000).easing(Easing.Quadratic.InOut).repeat(Infinity),
      ],
    },
    eggRight: {
      path: eggRight,
      x: 75,
      y: 0,
      width: 455,
      height: 506,
      scrollOffset: 1,
      frame: 0,
      frames: 3,
      rotate: 0,
      originX: 455 / 2,
      originY: 506,
      animations: (_this) => [
        new Tween(_this)
          .to({ rotate: 5 }, 250)
          .chain(
            new Tween(_this)
              .to({ rotate: -5 }, 500)
              .chain(new Tween(_this).to({ rotate: 5 }, 500).easing(Easing.Quadratic.InOut).delay(1000).yoyo(true).repeat(Infinity))
          ),
        new Tween(_this).delay(400).to({ frame: _this.frames }, 250).delay(600).easing(Easing.Quadratic.InOut).repeat(Infinity),
      ],
    },
  });
  const sources = sourceLookup.current;

  // Offset images based off of the scroll
  const drawEgg = () => {
    const ctx = canvasRef.current?.getContext('2d');
    const bufferCtx = bufferRef.current?.getContext('2d');
    if (ctx) {
      ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
      bufferCtx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
      const renderImages = (ctx) => {
        for (let src in sources) {
          const {
            image,
            x,
            y,
            width,
            height,
            opacity = 1,
            frame = 0,
            hide,
            scale = 1,
            rotate = 0,
            originX = 0,
            originY = 0,
          } = sources[src];
          if (!hide) {
            // Change image opacity
            ctx.save();
            ctx.globalAlpha = opacity;
            ctx.translate(x + originX, y + originY);
            ctx.rotate((rotate * Math.PI) / 180);
            ctx.drawImage(
              image,
              Math.floor(frame) * width,
              0,
              width,
              height,
              x - ((x + originX) * 2 * scale) / 2,
              y - ((y + originY) * 2 * scale) / 2,
              width * scale,
              height * scale
            );
            ctx.restore();
          }
        }
      };
      renderImages(bufferCtx);
      renderImages(ctx);
      ctx.save();
      ctx.drawImage(bufferRef.current, 0, 0);
      ctx.restore();
    }
  };
  // DRAW LOOP
  const _update = useCallback(function update(time) {
    drawEgg();
    requestAnimationFrame(update); // requests the next frame in 1/60th second
    tweenUpdate(time);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const start = () => {
    if (sources) Object.values(sources).forEach((x) => x?.animations?.forEach((y) => y.start()));
    requestAnimationFrame(_update);
  };

  // Preload images and store them in the sources data
  const loadImages = () => {
    var loadedImages = 0;
    const numImages = Object.values(sources).filter((x) => x.path).length;
    for (let src in sources) {
      if (sources[src].path) {
        sources[src].image = new Image();
        // eslint-disable-next-line no-loop-func
        sources[src].image.onload = () => {
          if (++loadedImages >= numImages) {
            start();
          }
        };
        sources[src].image.src = sources[src].path;
      }
    }
  };
  // Load images on component mount
  useEffect(() => {
    if (!isLoaded.current) {
      loadImages();
      isLoaded.current = true;
    }
    Object.values(sources).forEach((source) => {
      if (typeof source.animations === 'function') source.animations = source.animations(source, sources);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles['container']}>
      <div className={styles['step']}>{step}</div>
      <canvas className={styles['eggCanvas']} ref={canvasRef} width="600" height="506"></canvas>
      <canvas style={{ display: 'none' }} ref={bufferRef} width="600" height="506"></canvas>

      {rooster && (
        <div className={styles['button-containers']}>
          <HatchButton rooster={rooster} />
        </div>
      )}
    </div>
  );
};
export default EggCanvas;
