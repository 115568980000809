import React from 'react';
import styles from './NameChangeForm.module.scss';
import Form from '../../Form/Form';
import FormInput from '../../Form/FormInput/FormInput';

const NameChangeForm = ({ step, onSubmit, disabled }) => {
  const handleSubmit = ([name]) => {
    onSubmit({ name: name.value });
  };
  return (
    <div className={styles['container']}>
      <div className={styles['step']}>{step}</div>
      <Form name="change-name-form" onSubmit={handleSubmit} className={styles['form']} disabled={disabled}>
        <FormInput
          id="rooster-name"
          label="Rooster Name"
          placeholder="Enter Rooster's Name"
          autoComplete="off"
          required
          pattern="^[A-Za-z,'.?!@#& -]+$"
          badPattern="[^A-Za-z,'.?!@#& -]"
        />
      </Form>
      <div className={styles['rules-container']}>
        <div className={styles['rules-title']}>Rules:</div>
        <ul className={styles['rules-ul']}>
          <li className={styles['rules-warning']}>Names are PERMANENT*</li>
          <li>Names must only contain letters, spaces and ,'.?!@#&-</li>
          <li>Names must be unique</li>
          <li>Names must be less than 50 characters</li>
          <li>Names must not contain profanity or obscenities, you may lose the right to name your rooster*</li>
        </ul>
      </div>
    </div>
  );
};
export default NameChangeForm;
