import { eventDispatch } from '../utils/EventListener';
import axios from './axios';

export const getRooster = (tokenId) => {
  return axios.get(`/rooster/${tokenId}`).then((r) => r.data);
};

export const countRoosters = () => {
  return axios.get(`/roosters`, { params: { count: true } }).then((r) => r.data);
};

export const getRoosters = (lastId = null, sort = 'rarity', limit = 50) => {
  return axios.get(`/roosters`, { params: { lastId, limit, sort } }).then((r) => r.data);
};

export const getRoostersByItem = (itemId = null) => {
  return axios.get(`/roosters`, { params: { itemId } }).then((r) => r.data);
};

export const getRoostersByAddress = (address) => {
  return axios.get(`/roosters`, { params: { address } }).then((r) => r.data);
};

export const addRandomRooster = (transactionId) => {
  return axios.post(`/rooster/generate`, { transactionId }).then((r) => r.data);
};

export const activateCoins = (tokenId) => {
  return axios
    .post(`/activate-coins`, { tokenId })
    .then((r) => r.data)
    .then((data) => {
      eventDispatch('rooster-modified');
      return data;
    });
};

export const hatchEgg = (tokenId, signature) => {
  return axios.post(`/hatch/${tokenId}`, { signature }).then((r) => r.data);
};

export const editRooster = (tokenId, items, params) => {
  return axios.post(`/rooster/${tokenId}`, { items, ...params }).then((r) => r.data);
};

export const changeRoosterName = (tokenId, name, signature) => {
  return axios.post(`/rooster/${tokenId}/change-name`, { signature, name }).then((r) => r.data);
};
