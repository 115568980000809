import ax from 'axios';

ax.defaults.headers.common['Content-Type'] = 'application/json';
ax.defaults.timeout = 120 * 1000; // Default 120 second timeout

const axios = ax.create({
  baseURL: `${process.env.REACT_APP_API_HOST}`,
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (!error.response) {
      throw new Error(error.message);
    }
    const e = new Error(error.response.data || '');
    e.code = error.response.status;
    throw e;
  }
);
export default axios;
