import { Client, CLIENT_EVENTS } from '@walletconnect/client';
import { ethers, Signer } from 'ethers';
import { EventEmitter } from 'events';

export const SIGNER_EVENTS = {
  init: 'init',
  uri: 'uri',
  open: 'open',
  close: 'close',
  statusUpdate: 'status_update',
};

const DEFAULT = {
  methods: ['eth_sendTransaction', 'personal_sign', 'eth_signTypedData'],
  //['eth_sendTransaction', 'personal_sign', 'eth_signTypedData', 'eth_signTransaction'],
  blockchain: 'eip155',
};
export class WalletConnectSigner extends Signer {
  events = new EventEmitter();
  initializing = false;
  pending = false;

  constructor(_opts = {}) {
    super();
    this.opts = {
      ...DEFAULT,
      ..._opts,
    };
    this.register();
  }
  connected() {
    return typeof this.session !== 'undefined';
  }

  connecting() {
    return this.pending;
  }
  // PUBLIC

  on(event, listener) {
    this.events.on(event, listener);
  }
  once(event, listener) {
    this.events.once(event, listener);
  }
  removeListener(event, listener) {
    this.events.removeListener(event, listener);
  }
  off(event, listener) {
    this.events.off(event, listener);
  }

  isWalletConnect() {
    return true;
  }

  // Returns a new instance of the Signer, connected to provider.
  // This MAY throw if changing providers is not supported.
  connect() {
    return new WalletConnectSigner(this.opts);
  }

  async open(opts = { onlyReconnect: false }) {
    try {
      this.pending = true;
      const client = await this.register();
      const { blockchain, chainId } = this.opts;
      const permissions = {
        blockchain: {
          chains: [`${blockchain}:${chainId}`],
        },
        jsonrpc: {
          methods: this.opts.methods,
        },
      };

      const supportedSession = await this.client.session.find(permissions);
      if (supportedSession.length > 0) {
        this.updateState(supportedSession[0]);
      } else if (!opts.onlyReconnect) {
        const confirmOpen = new Promise((resolve) => {
          this.client.on(CLIENT_EVENTS.session.created, (_) => {
            resolve(true);
          });
        });
        await client.connect({
          permissions,
        });
        await confirmOpen;
      } else {
        // console.log(`onlyReconnect is ${opts.onlyReconnect} and found no supported sessions`);
      }
      this.onOpen();
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async close() {
    if (typeof this.session === 'undefined') {
      return;
    }
    const client = await this.register();
    const confirmClose = new Promise((resolve) => {
      this.client.on(CLIENT_EVENTS.session.deleted, (_) => {
        resolve(true);
      });
    });
    await client.disconnect({
      topic: this.session.topic,
      reason: {
        code: 123,
        message: 'WalletConnectSigner closed.',
      },
    });
    await confirmClose;
    this.onClose();
  }

  getAddress() {
    if (!this.accounts) {
      throw Error('client must be enabled before you can list accounts.');
    }
    // return AccountId.parse(this.accounts[0]).address;
    return this.accounts[0].split(':').pop();
  }

  // Returns the signed prefixed-message. This MUST treat:
  // - Bytes as a binary message
  // - string as a UTF8-message
  // i.e. "0x1234" is a SIX (6) byte string, NOT 2 bytes of data
  async signMessage(message) {
    if (typeof this.client === 'undefined') {
      this.client = await this.register();
      if (!this.connected) await this.open();
    }
    if (typeof this.session === 'undefined') {
      throw new Error('Signer connection is missing session for signMessage');
    }
    const wc = await this.register();
    const address = this.getAddress();
    const res = await wc.request({
      request: {
        method: 'personal_sign',
        params: [message, address],
      },
      topic: wc.session.topics[0],
    });
    return res;
  }

  // Signs a transaction and returns the fully serialized, signed transaction.
  // The EXACT transaction MUST be signed, and NO additional properties to be added.
  // - This MAY throw if signing transactions is not supports, but if
  //   it does, sentTransaction MUST be overridden.
  async signTransaction(transaction) {
    if (typeof this.client === 'undefined') {
      this.client = await this.register();
      if (!this.connected) await this.open();
    }
    if (typeof this.session === 'undefined') {
      throw new Error('Signer connection is missing session for signTransaction');
    }
    transaction = {
      ...transaction,
      gasLimit: ethers.BigNumber.from(transaction.gasLimit).toHexString(),
      gasPrice: ethers.BigNumber.from(transaction.gasPrice).toHexString(),
    };
    const res = await this.client.request({
      request: {
        method: 'eth_signTransaction',
        params: [transaction],
      },
      topic: this.session.topic,
    });
    return res;
  }

  async request(method, params) {
    if (typeof this.client === 'undefined') {
      this.client = await this.register();
      if (!this.connected) await this.open();
    }
    if (typeof this.session === 'undefined') {
      throw new Error('Signer connection is missing session for request');
    }
    const res = await this.client.request({
      request: {
        method: method,
        params: params,
      },
      topic: this.session.topic,
    });
    return res;
  }

  // ---------- private ----------------------------------------------- //

  async register() {
    const opts = { projectId: this.opts.projectId, metadata: this.opts.metadata };
    if (typeof this.client !== 'undefined') {
      return this.client;
    }
    if (this.initializing) {
      return new Promise((resolve, reject) => {
        this.events.once(SIGNER_EVENTS.init, () => {
          if (typeof this.client === 'undefined') {
            return reject(new Error('Client not initialized'));
          }
          resolve(this.client);
        });
      });
    }
    this.initializing = true;
    this.client = await Client.init(opts);
    this.initializing = false;
    this.registerEventListeners();
    this.events.emit(SIGNER_EVENTS.init);
    return this.client;
  }

  onClose() {
    this.pending = false;
    if (this.client) {
      this.client = undefined;
    }
    this.events.emit(SIGNER_EVENTS.close);
  }

  onOpen(session) {
    this.pending = false;
    if (session) {
      this.session = session;
      this.events.emit(SIGNER_EVENTS.open);
    }
  }

  async updateState(session) {
    this.session = session;
    const { accounts } = session.state;
    // Check if accounts changed and trigger event
    if (!this.accounts || (accounts && this.accounts !== accounts)) {
      this.accounts = accounts;
    }
    this.events.emit(SIGNER_EVENTS.statusUpdate, session);
    // TODO chainChanged, networkChanged, rpcChanged, BlockchainChanged? :D
  }

  registerEventListeners() {
    if (typeof this.client === 'undefined') return;
    // Sessions
    this.client.on(CLIENT_EVENTS.session.updated, async (session) => {
      if (!this.session || this.session?.topic !== session.topic) return;
      this.updateState(session);
    });
    this.client.on(CLIENT_EVENTS.session.created, (session) => {
      this.updateState(session);
    });
    this.client.on(CLIENT_EVENTS.session.deleted, (_session) => {
      this.onClose();
    });
    // Pairing
    this.client.on(CLIENT_EVENTS.pairing.proposal, async (proposal) => {
      const uri = proposal.signal.params.uri;
      this.events.emit(SIGNER_EVENTS.uri, uri);
    });
  }
}
