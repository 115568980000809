import { combineReducers } from 'redux';
import roostersReducer from './roosters/roosters.reducer';
import transactionsReducer from './transactions/transactions.reducer';
import walletReducer from './wallet/wallet.reducer';
const combinedRootReducer = combineReducers({
  transactions: transactionsReducer,
  wallet: walletReducer,
  roosters: roostersReducer,
});

const rootReducer = (state, action) => {
  return combinedRootReducer(state, action);
};
export default rootReducer;
