import React, { useEffect, useState } from 'react';
import styles from './FormInput.module.scss';
import classNames from 'classnames';

const FormInput = ({ id, label, onChange, value, required, children, classes, badPattern, pattern, ...props }) => {
  const [error, setError] = useState(null);
  const [state, setState] = useState('');
  const handleChange = (event) => {
    event.preventDefault();
    const value = event.target.value.trim();
    let error = false;
    if (required && !value) {
      error = true;
      setError('Missing required field');
    } else if (value && badPattern && value.match(badPattern)) {
      error = true;
      setError(`Invalid Character ${value.match(badPattern)[0]}`);
    } else {
      setError(false);
    }
    if (onChange) onChange(error, value);
    setState(event.target.value);
  };
  useEffect(() => {
    if (value) {
      setState(value);
      setError(false);
    }
  }, [value]);
  return (
    <div className={styles.formField}>
      <div className={styles.inputField}>
        <label id={id} className={styles.label} htmlFor={id}>
          {label}:
        </label>
        <div className={classNames(styles.inputWrapper, classes.inputWrapper)}>
          <input
            aria-labelledby={id}
            name={id}
            className={styles.input}
            pattern={pattern}
            type="text"
            required={required}
            label={label}
            {...props}
            onChange={handleChange}
            value={state}
          />
          {children}
        </div>
      </div>
      <div className={styles.error}>{error}</div>
    </div>
  );
};
FormInput.defaultProps = {
  classes: {},
};

export default FormInput;
