import {
  ADD_TRANSACTION,
  CONFIRM_TRANSACTION,
  COMPLETE_TRANSACTION,
  REJECT_CURRENT_TRANSACTION,
  SET_CURRENT_TRANSACTION,
  ONGOING_TRANSACTION,
  ATTEMPT_PREVIOUS_TRANSACTION,
} from '../types';

export const addTransaction = (payload) => (dispatch) => {
  dispatch({ type: ADD_TRANSACTION, payload });
};

export const setOngoingTransaction = (ongoingTransaction) => (dispatch) => {
  dispatch({ type: ONGOING_TRANSACTION, payload: { ongoingTransaction } });
};

export const confirmTransaction = (payload) => (dispatch) => {
  dispatch({ type: CONFIRM_TRANSACTION, payload });
};

export const completeTransaction = (payload) => (dispatch) => {
  dispatch({ type: COMPLETE_TRANSACTION, payload });
};

export const attemptPreviousTransaction = (payload) => (dispatch) => {
  dispatch({ type: ATTEMPT_PREVIOUS_TRANSACTION, payload });
};

export const setCurrentTransaction = (payload) => (dispatch) => {
  dispatch({ type: SET_CURRENT_TRANSACTION, payload });
};

export const rejectCurrentTransaction = (payload) => (dispatch) => {
  dispatch({ type: REJECT_CURRENT_TRANSACTION, payload });
};
