import React, { useEffect, useRef, useCallback } from 'react';
import styles from './RoosterCanvas.module.scss';
import { Tween, update as tweenUpdate } from '@tweenjs/tween.js';

const RoosterCanvas = ({ rooster, step, onLoad }) => {
  const canvasRef = useRef(null);
  const isLoaded = useRef(false);
  useEffect(() => onLoad(), []);
  // Storage for image data
  const sourceLookup = useRef({
    oldImage: {
      path: localStorage.getItem('rrc:oldImage'),
      width: 600,
      height: 600,
      scrollOffset: 1,
      opacity: 1,
    },
    newImage: {
      path: localStorage.getItem('rrc:newImage'),
      width: 600,
      height: 600,
      scrollOffset: 1,
      opacity: 0,
      animations: (_this) => [new Tween(_this).to({ opacity: 1 }, 1000).delay(500).yoyo(true).repeat(Infinity)],
    },
  });
  const sources = sourceLookup.current;

  // Offset images based off of the scroll
  const drawEdit = () => {
    const ctx = canvasRef.current?.getContext('2d');
    if (ctx) {
      ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
      const renderImages = (ctx) => {
        for (let src in sources) {
          const { image, width, height, opacity = 1, hide } = sources[src];
          if (!hide) {
            // Change image opacity
            ctx.save();
            ctx.globalAlpha = opacity;
            ctx.drawImage(image, 0, 0, width, height);
            ctx.restore();
          }
        }
      };
      renderImages(ctx);
    }
  };
  // DRAW LOOP
  const _update = useCallback(function update(time) {
    drawEdit();
    requestAnimationFrame(update); // requests the next frame in 1/60th second
    tweenUpdate(time);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const start = () => {
    if (sources) Object.values(sources).forEach((x) => x?.animations?.forEach((y) => y.start()));
    requestAnimationFrame(_update);
  };

  // Preload images and store them in the sources data
  const loadImages = () => {
    var loadedImages = 0;
    const numImages = Object.values(sources).filter((x) => x.path).length;
    for (let src in sources) {
      if (sources[src].path) {
        sources[src].image = new Image();
        // eslint-disable-next-line no-loop-func
        sources[src].image.onload = () => {
          if (++loadedImages >= numImages) {
            start();
          }
        };
        sources[src].image.src = sources[src].path;
      }
    }
  };
  // Load images on component mount
  useEffect(() => {
    if (!isLoaded.current) {
      loadImages();
      isLoaded.current = true;
    }
    Object.values(sources).forEach((source) => {
      if (typeof source.animations === 'function') source.animations = source.animations(source, sources);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles['container']}>
      <div className={styles['step']}>{step}</div>
      <canvas className={styles['roosterCanvas']} ref={canvasRef} width="600" height="600"></canvas>
    </div>
  );
};
export default RoosterCanvas;
