import React, { useState } from 'react';
import Button from '../Button/Button';
import styles from './Form.module.scss';

const Form = ({ className, name, children, onSubmit, error: parentError, disabled }) => {
  const [error, setError] = useState(null);

  const handleSubmit = (e) => {
    const data = Array.from(document.forms[name]).map(({ name, value, validity }) => ({ name, value, validity }));
    if (!data.some((d) => !d.validity.valid)) {
      setError(null);
      onSubmit(data);
    } else {
      if (data.some((d) => d.validity.valueMissing)) setError('Enter the information in all of the fields');
      else if (data.some((d) => d.validity.patternMismatch)) setError('Invalid Character');
      else setError('Check the form fields for errors');
    }
    return false;
  };
  return (
    <form id={name} name={name} className={className}>
      {children}

      <Button onClick={handleSubmit} className={styles.submitButton} disabled={disabled}>
        Submit
      </Button>
      <div className={styles.error}>{parentError || error}</div>
    </form>
  );
};
export default Form;
