import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux/es/hooks/useDispatch';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import { purchase } from '../../../store/purchases/purchases.actions';
import Button from '../Button/Button';
import { GiEggEye } from 'react-icons/gi';

const HatchButton = ({ rooster }) => {
  const [timeToHatch, setTimeToHatch] = useState(null);
  const dispatch = useDispatch();
  const { currentTransaction } = useSelector((state) => state.transactions);

  useEffect(() => {
    if (rooster) {
      const setTTH = () =>
        setTimeToHatch(
          Math.max(
            (new Date(1646859600000).getTime() - new Date().getTime()) / 1000,
            86400 - (new Date().getTime() - (rooster.createDate || 0)) / 1000
          )
        );
      setTTH();
      const x = setInterval(setTTH, 10000);
      return () => clearInterval(x);
    }
  }, [rooster]);

  let timeTill = '';
  if (Math.floor(timeToHatch / 86400) > 1) {
    timeTill = Math.floor(timeToHatch / 86400) + ' days';
  } else if (Math.floor(timeToHatch / 3600) > 1) {
    timeTill = Math.floor(timeToHatch / 3600) + ' hours';
  } else if (Math.floor(timeToHatch / 60) > 1) {
    timeTill = Math.floor(timeToHatch / 60) + ' minutes';
  } else {
    timeTill = timeToHatch + ' seconds';
  }

  const sendHatch = async () => {
    if (timeToHatch <= 0 && !currentTransaction) {
      dispatch(purchase('hatch-egg', { tokenId: rooster.tokenId, rooster: rooster }));
    }
  };
  return (
    <>
      {rooster?.isEgg && timeToHatch != null && (
        <div>
          <Button disabled={timeToHatch > 0 || !!currentTransaction} onClick={sendHatch} icon={<GiEggEye size="32" />}>
            {timeToHatch <= 0 ? 'Hatch Egg' : `Hatch in ${timeTill}`}
          </Button>
        </div>
      )}
    </>
  );
};
export default HatchButton;
