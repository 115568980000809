import { SET_ROOSTERS } from '../types';

const initialState = {
  roosters: null,
  count: 0,
  countLeft: 8888,
};

const roostersReducer = (state = initialState, action) => {
  if (action.type === SET_ROOSTERS) {
    return {
      ...state,
      ...action.payload,
    };
  } else if (action.type === SET_ROOSTERS) {
    return {
      ...state,
      count: action.payload.count,
      countLeft: 8888 - action.payload.count,
    };
  } else return state;
};
export default roostersReducer;
