export const ADD_TRANSACTION = 'ADD_TRANSACTION';
export const CONFIRM_TRANSACTION = 'CONFIRM_TRANSACTION';
export const COMPLETE_TRANSACTION = 'COMPLETE_TRANSACTION';
export const ATTEMPT_PREVIOUS_TRANSACTION = 'ATTEMPT_PREVIOUS_TRANSACTION';
export const SET_CURRENT_TRANSACTION = 'SET_CURRENT_TRANSACTION';
export const REJECT_CURRENT_TRANSACTION = 'REJECT_CURRENT_TRANSACTION';
export const SET_ADDRESS = 'SET_ADDRESS';
export const SET_CONNECTED = 'SET_CONNECTED';
export const SET_CONNECTOR = 'SET_CONNECTOR';
export const SET_WALLET_CONNECT_SIGNER = 'SET_WALLET_CONNECT_SIGNER';
export const SET_WALLET_ERROR = 'SET_WALLET_ERROR';
export const SET_ROOSTERS = 'SET_ROOSTERS';
export const COUNT_ROOSTERS = 'COUNT_ROOSTERS';
export const ONGOING_TRANSACTION = 'ONGOING_TRANSACTION';
