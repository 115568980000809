import React from 'react';
import styles from './Terms.module.scss';

const Terms = () => {
  return (
    <div className={styles['footer']}>
      <h1>NFT Terms of Use</h1>
      <br />
      <h2>1. Definitions</h2>
      <p>The group known as the "Rowdy Rooster Collective" (RRC) is defined as the creators and equity shareholders of the artwork. </p>
      <p>
        The artwork discussed is a series of "Items" published by the Rowdy Rooster Collective. Items are used in conjunction with others to
        form a unique Derivative artwork.
      </p>
      <br />
      <p>
        A Non-Fungible Token (NFT) is record that identifies a token that is associated to an ethereum address. The ethereum address is
        owned by a person or group, and control is designated by the creator of the ethereum address.
      </p>
      <p>
        The NFT is further defined as associated with a "Single Derivative" artwork, this "Single Derivative" artwork is the current state
        of the Derivative artwork associated to the NFT. The artwork associated with the "Single Derivative" is subject to change by RRC.
      </p>
      <p>Associations are defined by RRC and subject to change.</p>
      <p>A Holder of a Derivative artwork is the person or group who owns, or has owned, an ethereum address associated with the NFT.</p>
      <p>
        The "Current Holder" of a Derivative artwork the person or group, who owns the ethereum address currently associated with the NFT.
      </p>
      <p>Use is defined as General Use and Commercial Use as defined by the Canadian Copyright Act</p>
      <br />

      <h2>2. Ownership</h2>
      <p>
        Items and all their Derivatives fall under the copyrights of the "Rowdy Rooster Collective" as defined by the Canadian Copyright
        Act, and RRC takes full ownership of them. The RRC offers a license agreement to the Holders and "Current Holder", of an NFT as
        defined which is subject to the License and Restrictions Section of this document.
      </p>
      <p>
        All Derivative artwork is under the full ownership and control of RRC. RRC may modify the current state of a Derivative or "Single
        Derivative" artwork at any time, or at the behest of the "Current Holder".
      </p>
      <br />

      <h2>3. License</h2>
      <p>
        Any "Current Holder" enters into a license agreement for a "Single Derivative" artwork with RRC defined by the "Terms of License"
        section of this document.
      </p>
      <p>The license agreement remains as long as the person or group is remains a Holder.</p>
      <p>
        If a "Current Holder" or Holder chooses to not enter into a license agreement or breaks the license agreement they may be subject to
        penalties, defined in the Canadian Copyright Act, for use of any artwork and Derivative artwork.
      </p>
      <br />

      <h2>4. Restrictions</h2>
      <p>This license agreement does not give a Holder a right to use branding from RRC.</p>
      <p>This license agreement does not give a Holder a right to use Items or Derivative artworks.</p>
      <p>
        NFTs can be sold by the "Current Holder" without RRC's consent. However, the license agreements to the Items and Derivative artworks
      </p>
      <br />

      <h2>5. Terms of License</h2>
      <p>
        License for the "Single Derivative" artwork is given to the Current Holder of the NFT for General Use and Commercial Use as defined
        by the Canadian Copyright Act and applicable laws.
      </p>
      <p>
        The "Current Holder" is able to ask RRC to modify the current state of the "Single Derivative" artwork. After modification of a
        "Single Derivative" artwork the license holder must cease the use of the artwork for any activity defined in this license.
      </p>
      <p>
        A Holder will be safe from persecution by the law for any use, as defined by this license agreement, of a "Single Derivative"
        artwork, if the Holder was the "Current Holder" of the "Single Derivative" artwork at the time of its use.
      </p>
      <p>
        A "Current Holder" may make agreements with other parties to use the "Single Derivative" artwork. However, any other parties which
        enter into such an agreement will be subject to the same limits and restrictions as the "Current Holder" for as long as the "Current
        Holder" who enters into the agreement remains a "Current Holder".
      </p>
    </div>
  );
};

export default Terms;
