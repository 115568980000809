import React, { useEffect } from 'react';
import Button from '../layout/components/Button/Button';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import { useDispatch } from 'react-redux/es/hooks/useDispatch';
import { setAddress, setConnected, setConnector, setWalletConnectSigner, setWalletError } from '../store/wallet/wallet.actions';
import QRCodeModal from '@walletconnect/qrcode-modal';
// import walletConnectIcon from '../assets/images/icons/walletConnect.png';
import coinbaseWalletIcon from '../assets/images/icons/coinbaseWallet.png';
import metamaskIcon from '../assets/images/icons/metaMask.png';
import { SIGNER_EVENTS, WalletConnectSigner } from './WalletConnectSigner';
export const hasWalletApp = () => {
  return !!window.ethereum;
};
const chainId = parseInt(process.env.REACT_APP_CHAIN_ID, 10);

export const getSelectedAddress = () => {
  if (window.ethereum && typeof window.ethereum?.isConnected === 'function') {
    return window.ethereum?.isConnected() && window.ethereum?.selectedAddress;
  } else return null;
};

const useConnect = () => {
  const { address, connected, connectorType, walletConnectSigner, error } = useSelector((state) => state.wallet);
  const dispatch = useDispatch();

  // Connect to extensions like metamask
  const walletAppConnect = () => {
    if (window.ethereum) {
      window.ethereum
        .request({ method: 'eth_requestAccounts' })
        .then(([account]) => {
          dispatch(setAddress(account));
          dispatch(setConnected(!!account));
        })
        .catch((e) => {
          if (e.code === -32002) {
            const message = `${
              window.ethereum.isMetaMask ? 'MetaMask' : window.ethereum.isCoinbaseWallet ? 'Coinbase Wallet' : 'Wallet App'
            } was closed during a previous connection attempt, either unlock your wallet or refresh and attempt to connect again.`;
            dispatch(setWalletError(message));
          } else dispatch(setWalletError(e?.message || e));
        });
    }
  };

  // If the connection type changes, init the connection
  useEffect(() => {
    if (connectorType === 'WalletApp') {
      if (window.ethereum) {
        const _setAddress = ([x]) => dispatch(setAddress(x, parseInt(window.ethereum.chainId, 16)));
        const _setChainId = (_chainId) => window.location.reload();
        const _setConnected = (x) => dispatch(setConnected(x));
        if (window.ethereum.on) {
          window.ethereum.on('accountsChanged', _setAddress);
          window.ethereum.on('connect', _setConnected);
          window.ethereum.on('chainChanged', _setChainId);
        } else if (window.ethereum.addListener) {
          window.ethereum.addListener('accountsChanged', _setAddress);
          window.ethereum.addListener('connect', _setConnected);
          window.ethereum.addListener('chainChanged', _setChainId);
        }
        walletAppConnect();
        return () => {
          if (window.ethereum.off) {
            window.ethereum.off('accountsChanged', _setAddress);
            window.ethereum.off('connect', _setConnected);
            window.ethereum.off('chainChanged', _setChainId);
          } else if (window.ethereum.removeListener) {
            window.ethereum.removeListener('accountsChanged', _setAddress);
            window.ethereum.removeListener('connect', _setConnected);
            window.ethereum.removeListener('chainChanged', _setChainId);
          }
        };
      } else {
        dispatch(setWalletError('Wallet App not detected, try turning it on or installing a wallet like MetaMask.'));
      }
    } else if (connectorType === 'WalletConnect') {
      if (!walletConnectSigner) {
        const signer = new WalletConnectSigner({
          blockchain: 'eip155',
          chainId: chainId,
          projectId: '10199edd83e48d1ae1795cf707ebd2d0',
          metadata: {
            name: 'Rowdy Rooster Collective',
            description: 'Rowdy Rooster Collective',
            url: 'https://rowdyrooster.io/',
            icons: ['https://rowdyrooster.io/cdn/assets/logoLW_s.png'],
          },
        });
        dispatch(setWalletConnectSigner(signer));
        signer.open();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectorType, walletConnectSigner, dispatch]);

  // If a wallet connect signer is used, then add listeners
  useEffect(() => {
    if (walletConnectSigner) {
      const update = () => {
        QRCodeModal.close();
        dispatch(setAddress(walletConnectSigner.getAddress(), chainId));
        dispatch(setConnected(true));
      };
      const disconnect = () => {
        QRCodeModal.close();
        dispatch(setAddress(null, null));
        dispatch(setConnected(false));
      };
      const uriUpdate = (uri) => {
        QRCodeModal.close();
        QRCodeModal.open(uri);
      };
      walletConnectSigner.on(SIGNER_EVENTS.uri, uriUpdate);
      walletConnectSigner.on(SIGNER_EVENTS.statusUpdate, update);
      walletConnectSigner.on(SIGNER_EVENTS.close, disconnect);

      return () => {
        walletConnectSigner.off(SIGNER_EVENTS.uri, uriUpdate);
        walletConnectSigner.off(SIGNER_EVENTS.statusUpdate, update);
        walletConnectSigner.off(SIGNER_EVENTS.close, disconnect);
      };
    }
  }, [walletConnectSigner, dispatch]);
  const disconnect = () => {
    if (connectorType === 'WalletConnect') {
      walletConnectSigner?.close();
    } else {
      dispatch(setAddress(null, null));
      dispatch(setConnected(false));
    }
  };
  // Manually start a connection if it was interupted, or change the type if the user wants to connect differently
  const startConnection = (type) => {
    if (type !== connectorType) {
      dispatch(setConnector(type));
    } else if (connectorType === 'WalletApp') {
      walletAppConnect();
    } else if (connectorType === 'WalletConnect') {
      walletConnectSigner.open();
    }
  };

  // Generate some standardized connection buttons
  const ConnectRender = ({ className, TagName = Button, wrapper = (x) => x }) => {
    return !address
      ? [
          <TagName
            key="cr_WalletApp"
            className={className}
            to="#"
            onClick={(e) => {
              e.preventDefault();
              hasWalletApp() ? startConnection('WalletApp') : window.open('https://metamask.io/download.html', '_blank');
            }}
            icon={
              window.ethereum?.isMetaMask ? (
                <img src={metamaskIcon} alt="MetaMask" width="32" height="32" />
              ) : window.ethereum?.isCoinbaseWallet ? (
                <img src={coinbaseWalletIcon} alt="Coinbase" width="32" height="32" />
              ) : (
                ''
              )
            }
          >
            {hasWalletApp()
              ? `Connect with ${
                  window.ethereum?.isMetaMask ? 'MetaMask' : window.ethereum?.isCoinbaseWallet ? 'Coinbase Wallet' : 'Wallet App'
                }`
              : 'Install MetaMask'}
          </TagName>,
          // <TagName
          //   key="cr_WalletConnect"
          //   className={className}
          //   to="#"
          //   onClick={(e) => {
          //     e.preventDefault();
          //     startConnection('WalletConnect');
          //   }}
          //   icon={<img src={walletConnectIcon} alt="Wallet Connect" width="32" height="32" />}
          // >
          //   Connect with Wallet Connect
          // </TagName>,
        ].map(wrapper)
      : [];
  };
  // Standard renderer to switch to a different wallet connection
  const SwitchRender = ({ className, TagName = Button, wrapper = (x) => x }) => {
    return address
      ? [
          ...(connectorType === 'WalletApp'
            ? [
                // <TagName
                //   key="sr_WalletConnect"
                //   className={className}
                //   to="#"
                //   onClick={(e) => {
                //     e.preventDefault();
                //     startConnection('WalletConnect');
                //   }}
                //   icon={<img src={walletConnectIcon} alt="Wallet Connect" width="32" height="32" />}
                // >
                //   Switch wallets via Wallet Connect
                // </TagName>,
              ]
            : connectorType === 'WalletConnect' && hasWalletApp()
            ? [
                <TagName
                  key="sr_WalletApp"
                  className={className}
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    startConnection('WalletApp');
                  }}
                  icon={
                    window.ethereum?.isMetaMask ? (
                      <img src={metamaskIcon} alt="MetaMask" width="32" height="32" />
                    ) : window.ethereum?.isCoinbaseWallet ? (
                      <img src={coinbaseWalletIcon} alt="Coinbase" width="32" height="32" />
                    ) : (
                      ''
                    )
                  }
                >
                  {`Connect with ${
                    window.ethereum?.isMetaMask ? 'MetaMask' : window.ethereum?.isCoinbaseWallet ? 'Coinbase Wallet' : 'Wallet App'
                  } instead`}
                </TagName>,
              ]
            : []),
          <TagName
            key="sr_disconnect"
            className={className}
            to="#"
            onClick={(e) => {
              e.preventDefault();
              disconnect();
            }}
          >
            Disconnect
          </TagName>,
        ].map(wrapper)
      : [];
  };
  return {
    ConnectRender,
    SwitchRender,
    address,
    connected,
    connectorType,
    walletAppConnect: () => startConnection('WalletApp'),
    walletConnectConnect: () => startConnection('WalletConnect'),
    error,
  };
};
export default useConnect;
