import React, { useEffect, useRef, useCallback, useState } from 'react';
import styles from './HatchCanvas.module.scss';
import hatch from '../../../../assets/images/egg/hatch-spritesheet.png';
import { Tween, update as tweenUpdate } from '@tweenjs/tween.js';

const HatchCanvas = ({ rooster, step, onLoad }) => {
  const canvasRef = useRef(null);
  const [started, setStarted] = useState(false);
  const isLoaded = useRef(false);
  useEffect(() => onLoad(), []);

  // Storage for image data
  const sourceLookup = useRef({
    hatch: {
      path: hatch,
      x: 60,
      y: 290,
      width: 1086,
      height: 625,
      scale: 0.36,
      frame: 0,
      frames: 108,
      frameWidthCount: 11,
      animations: (_this) => [new Tween(_this).to({ frame: 51 }, 3000).repeat(Infinity)],
    },
    rooster: {
      path: null,
      x: 0,
      y: 0,
      width: 2000,
      height: 2000,
      scale: 0.25,
      hide: true,
      opacity: 0,
    },
  });
  const sources = sourceLookup.current;
  useEffect(() => {
    if (started && rooster?.isEgg === false) {
      sources.rooster.image = new Image();
      sources.rooster.image.src = rooster.image;
      sources.hatch.animations.forEach((x) => x.stop());
      new Tween(sources.hatch)
        .to({ frame: sources.hatch.frames - 1 }, Math.floor((6000 * (sources.hatch.frames - sources.hatch.frame)) / sources.hatch.frames))
        .onComplete(() => {
          sources.rooster.hide = false;
          new Tween(sources.rooster).delay(1000).to({ opacity: 1 }, 1000).start();
        })
        .start();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rooster?.isEgg, started]);

  // Offset images based off of the scroll
  const drawEgg = () => {
    const ctx = canvasRef.current?.getContext('2d');
    if (ctx) {
      ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
      const renderImages = (ctx) => {
        for (let src in sources) {
          const {
            image,
            x,
            y,
            width,
            height,
            opacity = 1,
            frame = 0,
            hide,
            scale = 1,
            originX = 0,
            originY = 0,
            frameWidthCount = 1,
          } = sources[src];
          if (!hide) {
            // Change image opacity
            ctx.save();
            ctx.globalAlpha = opacity;
            ctx.drawImage(
              image,
              Math.floor(frame % frameWidthCount) * width,
              Math.floor(frame / frameWidthCount) * height,
              width,
              height,
              x - ((x + originX) * 2 * scale) / 2,
              y - ((y + originY) * 2 * scale) / 2,
              width * scale,
              height * scale
            );
            ctx.restore();
          }
        }
      };
      renderImages(ctx);
    }
  };
  // DRAW LOOP
  const _update = useCallback(function update(time) {
    drawEgg();
    requestAnimationFrame(update); // requests the next frame in 1/60th second
    tweenUpdate(time);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const start = () => {
    if (sources) Object.values(sources).forEach((x) => x?.animations?.forEach((y) => y.start()));
    requestAnimationFrame(_update);
    setStarted(true);
  };

  // Preload images and store them in the sources data
  const loadImages = () => {
    var loadedImages = 0;
    const numImages = Object.values(sources).filter((x) => x.path).length;
    for (let src in sources) {
      if (sources[src].path) {
        sources[src].image = new Image();
        // eslint-disable-next-line no-loop-func
        sources[src].image.onload = () => {
          if (++loadedImages >= numImages) {
            start();
          }
        };
        sources[src].image.src = sources[src].path;
      }
    }
  };
  // Load images on component mount
  useEffect(() => {
    if (!isLoaded.current) {
      loadImages();
      isLoaded.current = true;
    }
    Object.values(sources).forEach((source) => {
      if (typeof source.animations === 'function') source.animations = source.animations(source, sources);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles['container']}>
      <div className={styles['step']}>{step}</div>
      <canvas className={styles['hatchCanvas']} ref={canvasRef} width="500" height="500"></canvas>
    </div>
  );
};
export default HatchCanvas;
