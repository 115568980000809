import React, { useEffect, useRef } from 'react';
import styles from './Popup.module.scss';

import classNames from 'classnames';
import { IoIosCloseCircle } from 'react-icons/io';

const Popup = ({ onClose, title, children, classes, hideX }) => {
  const bodyRef = useRef();
  useEffect(() => {
    const bodyClass = styles['body'];
    if (!document.body.classList.contains(bodyClass)) {
      document.body.style = `margin-right: ${window.innerWidth - document.body.offsetWidth}px`;
      document.body.classList.add(bodyClass);
    }
    return () => {
      document.body.style = null;
      document.body.classList.remove(bodyClass);
    };
  }, []);
  const handleClick = (e) => {
    if (!bodyRef.current.contains(e.target)) onClose();
  };
  return (
    <div className={classNames(styles.popup, classes.popup)} onMouseDown={handleClick}>
      <div ref={bodyRef} className={classNames(styles.popupBody, classes.popupBody)}>
        {title && (
          <div className={classNames(styles.title, classes.title)}>
            <h2>{title}</h2>
            {!hideX && (
              <div onClick={onClose} className={styles.closeIcon}>
                <IoIosCloseCircle size="32" />
              </div>
            )}
          </div>
        )}

        <div className={classNames(styles.bodyContainer, classes.bodyContainer)}>{children}</div>
      </div>
    </div>
  );
};

Popup.defaultProps = {
  classes: {},
  hideX: false,
};
export default Popup;
