import { REHYDRATE } from 'redux-persist/es/constants';
import { getSelectedAddress } from '../../utils/useConnect';
import { SET_ADDRESS, SET_CONNECTED, SET_CONNECTOR, SET_WALLET_CONNECT_SIGNER, SET_WALLET_ERROR } from '../types';

const initialState = {
  connectorType: null,
  connected: false,
  address: null,
  error: null,
  walletConnectSigner: null,
  signer: null,
  provider: null,
  chainId: null,
};

const walletReducer = (state = initialState, action) => {
  if (action.type === SET_ADDRESS) {
    return {
      ...state,
      error: action.payload.address ? null : state.error,
      ...action.payload,
    };
  } else if (action.type === SET_CONNECTED) {
    return {
      ...state,
      error: action.payload.connected ? null : state.error,
      ...action.payload,
    };
  } else if (action.type === SET_CONNECTOR) {
    return {
      ...state,
      error: null,
      ...action.payload,
    };
  } else if (action.type === SET_WALLET_CONNECT_SIGNER) {
    return {
      ...state,
      error: null,
      ...action.payload,
    };
  } else if (action.type === SET_WALLET_ERROR) {
    return {
      ...state,
      ...action.payload,
    };
  } else if (action.type === REHYDRATE) {
    const selectedAddress = getSelectedAddress();
    if (selectedAddress) {
      return {
        ...state,
        connectorType: 'WalletApp',
        connected: true,
        address: selectedAddress,
      };
    } else {
      return state;
    }
  } else return state;
};
export default walletReducer;
