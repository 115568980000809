import {
  ADD_TRANSACTION,
  CONFIRM_TRANSACTION,
  COMPLETE_TRANSACTION,
  SET_CURRENT_TRANSACTION,
  REJECT_CURRENT_TRANSACTION,
  ATTEMPT_PREVIOUS_TRANSACTION,
  ONGOING_TRANSACTION,
} from '../types';
import { REHYDRATE } from 'redux-persist/es/constants';

const initialState = {
  pool: {},
  currentTransaction: null,
  ongoingTransaction: false,
};

const transactionsReducer = (state = initialState, action) => {
  if (action.type === ADD_TRANSACTION) {
    const { hash, ...args } = action.payload;
    return {
      ...state,
      pool: { ...state.pool, [hash]: { hash: hash, ...args, confirmed: false } },
    };
  } else if (action.type === CONFIRM_TRANSACTION) {
    const { hash, ...args } = action.payload;
    const poolItem = state.pool[hash] || {};
    return {
      ...state,
      pool: { ...state.pool, [hash]: { ...poolItem, hash: hash, ...args, confirmed: true } },
    };
  } else if (action.type === ONGOING_TRANSACTION) {
    const { ongoingTransaction } = action.payload;
    return {
      ...state,
      ongoingTransaction: ongoingTransaction,
    };
  } else if (action.type === COMPLETE_TRANSACTION) {
    const { hash } = action.payload;
    const temp = { ...state.pool };
    delete temp[hash];
    return {
      ...state,
      pool: temp,
      currentTransaction: null,
    };
  } else if (action.type === ATTEMPT_PREVIOUS_TRANSACTION) {
    if (state.ongoingTransaction) return state;
    let tx = action.payload;
    if (tx.hash) {
      state.pool[tx.hash].attempt = (state.pool[tx.hash]?.attempt || 1) + 1;
      if (state.pool[tx.hash].attempt > 4) {
        delete state.pool[tx.hash];
        tx = null;
      }
    }
    return {
      ...state,
      currentTransaction: tx,
    };
  } else if (action.type === SET_CURRENT_TRANSACTION) {
    return {
      ...state,
      currentTransaction: action.payload,
    };
  } else if (action.type === REJECT_CURRENT_TRANSACTION) {
    return {
      ...state,
      currentTransaction: null,
    };
  } else if (action.type === REHYDRATE) {
    return {
      ...state,
      ...action.payload?.transactions,
      currentTransaction: null,
      ongoingTransaction: false,
    };
  } else return state;
};
export default transactionsReducer;
