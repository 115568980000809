import { ethers } from 'ethers';
import BigInt from 'big-integer';
import { getContractAddress, getPaymentAddress } from '../../api/addresses';
import { getCost } from '../../api/cost';
import { addRandomRooster, editRooster, hatchEgg, changeRoosterName } from '../../api/roosters';
import { saveTransaction } from '../../api/transactions';
import {
  addTransaction,
  completeTransaction,
  confirmTransaction,
  setCurrentTransaction,
  setOngoingTransaction,
} from '../transactions/transactions.actions';
import { checkWhitelist } from '../../api/whitelist';
import { eventDispatch } from '../../utils/EventListener';
const chainId = parseInt(process.env.REACT_APP_CHAIN_ID, 10);

function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
let savedSigner = null;
let savedProvider = null;
const getProvider = () => {
  if (!savedProvider) {
    if (!window.ethereum) {
      throw new Error('No Wallet Found');
    }
    savedProvider = new ethers.providers.Web3Provider(window.ethereum);
    return savedProvider;
  } else {
    return savedProvider;
  }
};
const getSigner = () => {
  if (!savedSigner) {
    const provider = getProvider();
    savedSigner = provider.getSigner();
    return savedSigner;
  } else {
    return savedSigner;
  }
};

const errors = {
  saveTransaction: 'Error Saving Transaction',
  sendTransaction: 'Either the transaction was cancelled or there was an error',
  cancelTransaction: 'The transaction was cancelled',
  ongoingTransaction: 'There is an ongoing transaction please wait',
  blockConfirmation: 'Error when waiting for the transaction confirmation',
  missingFromWhitelist: 'Address is not on the whitelist',
  insufficientFunds: 'Insufficient funds for gas * price + ',
  wrongChain: 'The incorrect ethereum chain is being used',
};
const randomRooster =
  (type, { hash }, eventCallback) =>
  async (dispatch, getState) => {
    const ongoingTransaction = getState().transactions.ongoingTransaction;
    if (ongoingTransaction) throw new Error(errors.ongoingTransaction);
    if (!hash) {
      const signer = getSigner();
      const contractAddress = (await getContractAddress()).address;
      const userAddress = await signer.getAddress();

      if(chainId !== parseInt(window.ethereum.chainId, 16)) throw new Error(errors.wrongChain);

      await checkWhitelist(userAddress).catch(() => {
        throw new Error(errors.missingFromWhitelist);
      });

      const cost = await getCost('random-rooster');
      const abi = ['function mintTo(address _to) payable'];
      const contract = new ethers.Contract(contractAddress, abi, signer);

      eventCallback('Saving Transaction...');
      const unsignedTX = await contract.populateTransaction.mintTo(userAddress, { value: cost });
      const nonce = await signer.getTransactionCount();
      await saveTransaction({ transaction: { ...unsignedTX, nonce }, type, parameters: {} }).catch(() => {
        throw new Error(errors.saveTransaction);
      });

      eventCallback('Waiting for transaction approval');
      dispatch(setOngoingTransaction(true));
      const tx = await signer.sendTransaction(unsignedTX).catch((e) => {
        dispatch(setOngoingTransaction(false));
        if (e.code === 4001) throw new Error(errors.cancelTransaction);
        if (e.code === 'INSUFFICIENT_FUNDS') throw new Error(errors.insufficientFunds + ethers.utils.formatEther(cost) + 'ETH');
        throw new Error(errors.sendTransaction);
      });
      hash = tx.hash;

      dispatch(addTransaction({ type, hash }));
    }

    eventCallback('Waiting for block confirmation...');
    await transactionWait(hash).catch(() => {
      throw new Error(errors.blockConfirmation);
    });
    dispatch(confirmTransaction({ type, hash }));
    if (!getState().transactions.currentTransaction) {
      dispatch(setCurrentTransaction({ type, restored: true }));
    }
    dispatch(setOngoingTransaction(false));

    eventCallback('Rendering Rooster...');
    const results = await addRandomRooster(hash);
    dispatch(completeTransaction({ type, hash }));
    eventDispatch('rooster-modified');
    eventCallback('Transaction Complete');
    return results;
  };
const customRooster =
  (type, { items, hash }, eventCallback) =>
  async (dispatch, getState) => {
    throw new Error('Not implemented');
    // if (!tx) {
    //   const signer = getSigner();
    //   if (!items) throw new Error('Missing Items');

    //   const contractAddress = (await getContractAddress()).address;
    //   const userAddress = await signer.getAddress();

    //   const cost = await getCost('custom-rooster', items);
    //   eventCallback('Waiting for transaction approval');
    //   const itemsHash = items.map((x) => new BigInt(x.replace(/-/g, ''), 16)).reduce((a, b) => a.xor(b.toString(10)), new BigInt(0));
    //   const abi = ['function mintToWithItems(address _to, uint256 items) payable'];
    //   const contract = new ethers.Contract(contractAddress, abi, signer);

    //   eventCallback('Saving Transaction...');
    //   const unsignedTX = await contract.populateTransaction.mintToWithItems(userAddress, '0x' + itemsHash.toString(16), { value: cost });
    //   const prepTransaction = await signer.populateTransaction(unsignedTX);
    //   await saveTransaction({ transaction: prepTransaction, type, parameters: { items } }).catch(() => {
    //     throw new Error(errors.saveTransaction);
    //   });

    //   eventCallback('Waiting for transaction approval');
    //   dispatch(setOngoingTransaction(true));
    //   tx = await signer.sendTransaction(unsignedTX).catch((e) => {
    //     dispatch(setOngoingTransaction(false));
    //     if (e.code === 4001) throw new Error(errors.cancelTransaction);
    //     throw new Error(errors.sendTransaction);
    //   });
    // }
    // eventCallback('Waiting for block confirmation...');
    // await transactionWait(tx.hash).catch(() => {
    //   throw new Error(errors.blockConfirmation);
    // });
    // dispatch(confirmTransaction({ type, tx }));
    // dispatch(setOngoingTransaction(false));

    // eventCallback('Rendering Rooster...');
    // const results = await addCustomRooster(items, tx.hash);
    // eventCallback('Transaction Complete');
    // return results;
  };
const purchaseItems =
  (type, { tokenId, items, hash }, eventCallback) =>
  async (dispatch, getState) => {
    // const signer = getSigner();
    throw new Error('Not implemented');
  };
const purchaseRoosterEdit =
  (type, { tokenId, items, hash, isSignature }, eventCallback) =>
  async (dispatch, getState) => {
    if (!hash) {
      const signer = getSigner();
      if (!tokenId) throw new Error('Missing tokenId');

      const userAddress = await signer.getAddress();
      const paymentAddress = (await getPaymentAddress()).address;
      if(chainId !== parseInt(window.ethereum.chainId, 16)) throw new Error(errors.wrongChain);

      const cost = await getCost('edit-rooster', items, tokenId);
      const itemsHash = items.map((x) => new BigInt(x.replace(/-/g, ''), 16)).reduce((a, b) => a.xor(b.toString(10)), new BigInt(0));
      // eslint-disable-next-line eqeqeq
      if (cost == 0) {
        eventCallback('Waiting for transaction approval');
        isSignature = true;
        hash = await signer.signMessage('Use your free edit to change your rooster').catch((e) => {
          if (e.code === 4001) throw new Error(errors.cancelTransaction);
          throw new Error(errors.sendTransaction);
        });
      } else {
        eventCallback('Saving Transaction...');
        const unsignedTX = {
          from: userAddress,
          to: paymentAddress,
          data: '0x' + itemsHash.toString(16),
          value: ethers.BigNumber.from(cost).toHexString(),
        };
        isSignature = false;
        const nonce = await signer.getTransactionCount();
        await saveTransaction({ transaction: { ...unsignedTX, nonce }, type, parameters: { items } }).catch((e) => {
          throw new Error(errors.saveTransaction);
        });

        eventCallback('Waiting for transaction approval');
        dispatch(setOngoingTransaction(true));
        const tx = await signer.sendTransaction(unsignedTX).catch((e) => {
          dispatch(setOngoingTransaction(false));
          if (e.code === 4001) throw new Error(errors.cancelTransaction);
          if (e.code === 'INSUFFICIENT_FUNDS') throw new Error(errors.insufficientFunds + ethers.utils.formatEther(cost) + 'ETH');
          throw new Error(errors.sendTransaction);
        });
        hash = tx.hash;
      }

      dispatch(addTransaction({ type, tokenId, items, hash, isSignature }));
    }
    if (!isSignature) {
      eventCallback('Waiting for block confirmation...');
      await transactionWait(hash).catch(() => {
        throw new Error(errors.blockConfirmation);
      });
    }
    dispatch(confirmTransaction({ type, tokenId, items, hash, isSignature }));
    if (!getState().transactions.currentTransaction) {
      dispatch(setCurrentTransaction({ type, restored: true }));
    }
    if (!isSignature) dispatch(setOngoingTransaction(false));

    eventCallback('Rendering Rooster...');
    const results = await editRooster(tokenId, items, { [isSignature ? 'signature' : 'transactionId']: hash });
    dispatch(completeTransaction({ type, tokenId, items, hash, isSignature }));
    eventDispatch('rooster-modified');
    eventCallback('Transaction Complete');
    return results;
  };
const hatch =
  (type, { tokenId, hash }, eventCallback) =>
  async (dispatch, getState) => {
    if (!hash) {
      const signer = getSigner();
      if(chainId !== parseInt(window.ethereum.chainId, 16)) throw new Error(errors.wrongChain);

      eventCallback('Waiting for transaction approval');
      hash = await signer.signMessage('Hatch Your Rooster!').catch((e) => {
        if (e.code === 4001) throw new Error(errors.cancelTransaction);
        throw new Error(errors.sendTransaction);
      });
    }
    eventCallback('Rendering Rooster...');
    let results = null;
    let attempt = 1;
    while (attempt <= 3 && !results) {
      attempt++;
      results = await hatchEgg(tokenId, hash).catch(() => null);
      if (!results) await timeout(5000);
    }
    eventDispatch('rooster-modified');
    eventCallback('Transaction Complete');
    dispatch(completeTransaction({ type }));
    return results;
  };

const changeName =
  (type, { tokenId, name, hash }, eventCallback) =>
  async (dispatch, getState) => {
    if (!hash) {
      const signer = getSigner();
      if(chainId !== parseInt(window.ethereum.chainId, 16)) throw new Error(errors.wrongChain);

      eventCallback('Waiting for transaction approval');
      hash = await signer.signMessage(`Set your rooster's name to '${name}' `).catch((e) => {
        if (e.code === 4001) throw new Error(errors.cancelTransaction);
        throw new Error(errors.sendTransaction);
      });
    }
    eventCallback('Changing Name...');
    const results = await changeRoosterName(tokenId, name, hash);
    eventDispatch('rooster-modified');
    eventCallback('Transaction Complete');
    return results;
  };

export const _purchase =
  (type, data = {}, eventCallback = () => {}) =>
  async (dispatch) => {
    if (type === 'random-rooster') {
      return await dispatch(randomRooster(type, data, eventCallback));
    } else if (type === 'custom-rooster') {
      return await dispatch(customRooster(type, data, eventCallback));
    } else if (type === 'items') {
      return await dispatch(purchaseItems(type, data, eventCallback));
    } else if (type === 'edit-rooster') {
      return await dispatch(purchaseRoosterEdit(type, data, eventCallback));
    } else if (type === 'hatch-egg') {
      return await dispatch(hatch(type, data, eventCallback));
    } else if (type === 'change-name') {
      return await dispatch(changeName(type, data, eventCallback));
    } else throw new Error('Purchase transaction Not Implemented');
  };
export const purchase =
  (type, data = {}) =>
  async (dispatch) => {
    dispatch(setCurrentTransaction({ type, ...data }));
  };
export const transactionWait = async (hash) => {
  const provider = getProvider();
  await provider.waitForTransaction(hash);
};
// export const restorePurchase = transactionId
//   .then(({type, transaction, parameters})=>{
//     purchase(type, {tx: {...transaction, hash:transactionId}, ...parameters})
//   });
